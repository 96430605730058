import React from "react";
import "./App.css";
import { FiArrowUpRight } from "react-icons/fi";
import { BiLogoGithub } from "react-icons/bi";

function App() {
  return (
    <div className="container">
      <div className="banner">
        <img
          src="https://64.media.tumblr.com/5fcbff9cd228d8b626eb4da6f71504bb/896c1ffc9d67dcd9-ba/s540x810/dea2b253f30d59799501b5ed9c4bb7df4bfd19f3.gifv"
          alt="takina.gif"
          className="banner-img"
        />
      </div>
      <div className="profile-picture-container">
        <img src="/profile.png" alt="profile" className="profile-img" />
      </div>
      <div className="content">
        <div className="user-info">
          <div className="name-info">
            <h2 className="display-name">Mochammad</h2>
            <div className="username-pronouns">
              <span className="username">elaxan</span>
              <span className="separator">•</span>
              <span className="pronouns">"Pronouns" is not defined</span>
            </div>
          </div>
          <div className="badges">
            <div className="badge transparent-badge" title="HypeSquad Balance">
              <img
                src="https://cdn.discordapp.com/badge-icons/3aa41de486fa12454c3761e8e223442e.png"
                alt="HypeSquad Balance Badge"
                className="badge-icon"
              />
            </div>
            <div className="badge transparent-badge" title="Active Developer">
              <img
                src="https://cdn.discordapp.com/badge-icons/6bdc42827a38498929a4920da12695d9.png"
                alt="Developer Badge"
                className="badge-icon"
              />
            </div>
            <div
              className="badge transparent-badge"
              title="Originally known as ElaXan#0001"
            >
              <img
                src="https://cdn.discordapp.com/badge-icons/6de6d34650760ba5551a79732e98ed60.png"
                alt="HashTag Badge"
                className="badge-icon"
              />
            </div>
            <div className="badge transparent-badge" title="Completed a Quest">
              <img
                src="https://cdn.discordapp.com/badge-icons/7d9ae358c8c5e118768335dbe68b4fb8.png"
                alt="Quest Badge"
                className="badge-icon"
              />
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="about-me">
          <h3>ABOUT ME</h3>
          <p>
            21 y.o. Introvert
            <br />
            I like playing games, watching anime, listening to music, and
            coding.
            <br />
            <br />
            Coding is fun until I have to give names to variables
          </p>
        </div>
        <div className="divider"></div>
        <div className="connections">
          <h3>CONNECTIONS</h3>
          <a
            href="https://github.com/elaxan"
            target="_blank"
            rel="noopener noreferrer"
            className="connections-link"
          >
            <div className="card">
              <BiLogoGithub className="icon" />
              <p className="platform">Github</p>
              <FiArrowUpRight className="arrow" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
